import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h6>{`July 14th, 2022, Seattle, WA`}</h6>
    <p>{`Follow me on Twitter: `}<a parentName="p" {...{
        "href": "https://twitter.com/colinmegill"
      }}>{`@colinmegill`}</a></p>
    <h1>{`Choosing Frontend Technologies`}</h1>
    <h6>{`1995-2012`}</h6>
    <p>{`When I was ten years old, I had an Atari computer that ran Sim City. I designed some signs, with graphics, for my Sicilian grandfather's barbershop in North Jersey. I printed them out on a dot matrix printer one by one. Paul Virgadamo was a veteran of WW2, fought for the Italians, and was a prisoner of war in Tunisia after Moussalini's infantry surrendered by the hundreds of thousands in North Africa. In the camp, he was a cook. In the US, where he emigrated and became a citizen, he was a barber and knew everyone in the neighborhood, and almost went broke when the Beatles landed and everyone stopped cutting their hair. He brought cheese from a local shop, olives, lamb, and squash he grew in his garden when he came to visit. The dot matrix printer was space age technology. `}</p>
    <p>{`I build for the web, now, professionally. `}<a parentName="p" {...{
        "href": "/"
      }}>{`I really enjoy this`}</a>{`. I love building with web technologies. I love that I can quickly deploy an experience in under an hour that anyone anywhere in the world can experience. I love how fast it is. The web is expressive, and powerful, and shareable. I love how seamless, interoperable, and immediate URLs are. I love that the package ecosystem is filled with legos that include everything from fetch shims to ui kits. I love how permissionless the web is.`}</p>
    <p>{`Still, I had a strange journey from BASIC to JS. After more BASIC programming on a TI-85, I hit C++ in high school that was sufficiently low level that I decided mistakenly that programming wasn't for me. After some simple HTML and Flash in high school, I tried 3ds Max and almost went to trade school in Seattle for CGI in 2003 to keep going with it. Ultimately majored in International Relations. IR turned out to have tons of maps, charts, graphs and otherwise, and when I saw D3 in the NYTimes, I thought it was probably the future of all information to be live, shareable and interactive. JS is wonderful.`}</p>
    <h6>{`2012-2021`}</h6>
    <p>{`So, I didn't get a CS degree. When I started programming, it was to build products and get stuff done, and now I've been doing that for over 10 years. The frontend ecosystem moves quickly. I get asked frequently which technologies to choose, which technologies I have chosen, and how to think about choosing technologies. The intention of this post is to document my thoughts. `}<em parentName="p">{`An aside: If you're looking to learn frontend, I recommend `}<a parentName="em" {...{
          "href": "https://eloquentjavascript.net/"
        }}>{`Eloquent JavaScript`}</a>{` and the most recent `}<a parentName="em" {...{
          "href": "https://frontendmasters.com/guides/"
        }}>{`Frontend Master's Guide`}</a>{`.`}</em></p>
    <p>{`I built with JQuery, Backbone and then learned and taught React. A recent `}<a parentName="p" {...{
        "href": "https://news.ycombinator.com/item?id=31967420"
      }}>{`Hacker News post about Vite`}</a>{` brought up all the familiar narratives. So I thought I'd respond, to that, but also more broadly. `}</p>
    <h6>{`2022`}</h6>
    <p>{`I've made the first changes in a while to my toolbox: I've swapped webpack for Vite. I've swapped redux for Recoil. I've swapped fetch for SWR. Collectively, these stand to save dozens of hours per year. They're powerful and expressive. `}</p>
    <p>{`...draft...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      